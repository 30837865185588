<template>
  <v-card id="invoice-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Invoice"
          class="invoice-list-search me-3"
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status"
        ></v-select>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="invoices"
      :options.sync="options"
      :loading="loading"
      show-select
      class="text-no-wrap"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel')
      }"
    >
      <template slot="no-data">
        {{ $t('noDataLabel') }}
      </template>

      <!-- Source -->
      <template #[`item.source`]="{item}">
        {{ item.source.charAt(0).toUpperCase() + item.source.slice(1) }}
      </template>

      <!-- Package -->
      <template #[`item.package`]="{item}">
        {{ item.package.charAt(0).toUpperCase() + item.package.slice(1) }}
      </template>


      <!-- Expired At -->
      <template #[`item.expired_at`]="{item}">
        {{ dateFormater(new Date(item.expired_at)) }}
      </template>


      <!-- Created At -->
      <template #[`item.created_at`]="{item}">
        {{ dateFormater(new Date(item.created_at)) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownloadOutline,
  mdiPencilOutline,
  mdiContentCopy,
} from '@mdi/js'

import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { TimeFormater } from '../../../helpers'

// composition function
import useInvoicesList from './useInvoiceList'

export default {
  data() {
    return {
      invoices: [],
      loading: false,
      user: {},
      tableColumns: [
        {
          text: '#ID',
          align: 'start',
          value: 'id_invoice',
        },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Source', value: 'source' },
        { text: 'Package', value: 'package' },
        { text: 'Extra', value: 'extra' },
        { text: 'Coupon', value: 'coupon' },
        { text: this.$t('subscriptions.ExpiredAt'), value: 'expired_at' },
        { text: this.$t('subscriptions.CreatedAt'), value: 'created_at' },
      ],
    }
  },
  async mounted() {
    await this.getInvoices()
  },

  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (!from.name) {
  //       vm.$router.push(
  //         {
  //           name: 'redirect',
  //           params: { page: 'invoice' },
  //         },
  //         () => {},
  //       )
  //     }
  //   })
  // },
  methods: {
    async getInvoices() {
      this.loading = true
      try {
        // eslint-disable-next-line camelcase
        const { sub_id } = this.$store.getters['auth/getUser']

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ sub_id }),
          headers: {
            'Content-Type': 'application/json',
          },
        }

        const response = await fetch(`${process.env.VUE_APP_DB_API_URL}superwa/couchdb/invoices/list`, requestOptions)

        const data = await response.json()
        if (data.status) {
          this.invoices = data.data
          this.loading = false
        } else if (data.data.length === 0) {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
  },
  setup() {
    const {
      invoiceListTable,
      searchQuery,
      options,
      statusFilter,
      totalInvoiceListTable,
      selectedTableData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    const statusOptions = ref(['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due'])

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const actionOptions = [
      { title: 'Download', icon: mdiDownloadOutline },
      { title: 'Edit', icon: mdiPencilOutline },
      { title: 'Delete', icon: mdiDeleteOutline },
      { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    return {
      searchQuery,
      statusOptions,
      statusFilter,
      options,
      totalInvoiceListTable,
      invoiceListTable,
      actions,
      selectedTableData,
      actionOptions,
      selectedAction,

      checkType,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
}
</style>
