var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"invoice-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"invoice-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search Invoice"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-select',{staticClass:"invoice-list-status",attrs:{"items":_vm.statusOptions,"single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Select Status"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.invoices,"options":_vm.options,"loading":_vm.loading,"show-select":"","footer-props":{
      'items-per-page-text': _vm.$t('rowsPerPageLabel')
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.source",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.source.charAt(0).toUpperCase() + item.source.slice(1))+" ")]}},{key:"item.package",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.package.charAt(0).toUpperCase() + item.package.slice(1))+" ")]}},{key:"item.expired_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormater(new Date(item.expired_at)))+" ")]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormater(new Date(item.created_at)))+" ")]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('noDataLabel'))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }