import {
  mdiAlertCircleOutline,
  mdiChartTimelineVariant,
  mdiCheckCircleOutline,
  mdiClose,
  mdiContentSaveOutline,
  mdiDownloadCircleOutline,
  mdiEmailOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useInvoicesList() {
  const invoiceListTable = ref([])

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['created_at', 'expired_at', 'source', 'package'],
    sortDesc: [true],
  })
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: mdiChartTimelineVariant }
    if (status === 'Paid') return { variant: 'success', icon: mdiCheckCircleOutline }
    if (status === 'Downloaded') return { variant: 'info', icon: mdiDownloadCircleOutline }
    if (status === 'Draft') return { variant: 'secondary', icon: mdiContentSaveOutline }
    if (status === 'Sent') return { variant: 'primary', icon: mdiEmailOutline }
    if (status === 'Past Due') return { variant: 'error', icon: mdiAlertCircleOutline }

    return { variant: 'secondary', icon: mdiClose }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'error'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'

    return 'primary'
  }

  return {
    searchQuery,
    options,
    invoiceListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,
  }
}
